<template>
	<div class="widePhotoScroll" ref="slider">
		<VuePerfectScrollbar class="widePhotoScroll__images">
			<div
				class="widePhotoScroll__image_wrap"
				v-for="(image, index) in images"
				:key="index"
			>
				<img v-lazy="image.src" class="widePhotoScroll__image" />
			</div>
		</VuePerfectScrollbar>
		<VueSlider
			v-model="value"
			tooltip="none"
			dotSize="42"
			height="3px"
			:min="min"
			:max="max"
			@dragging="dragSlider"
		>
		</VueSlider>
	</div>
</template>

<style lang="sass">
@import '@/assets/sass/WidePhotoScroll'
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueSlider from "vue-slider-component";

export default {
	name: "WidePhotoScroll",
	props: ["images"],
	data: () => ({
		value: 0,
	}),
	components: { VuePerfectScrollbar, VueSlider },
	methods: {
		dragSlider: function() {
			var position = (this.value / this.max) * 100;
			var element = this.$el.getElementsByClassName(
				"photoScroll__photos"
			)[0];
			var container = this.$el.getElementsByClassName(
				"photoScroll__container"
			)[0];
			var width = this.$el.offsetWidth;

			var childrens = element.childNodes;
			var fullWidth = 0;

			childrens.forEach(function(element, index) {
				fullWidth += element.offsetWidth;
			});

			fullWidth += childrens.length * 30 - 30;

			container.scrollLeft = ((fullWidth - width) / 100) * position;
		},
	},
	mounted() {
		var sliderEl = this.$refs.slider;
		sliderEl.style.marginLeft = `-${sliderEl.offsetLeft}px`
	}
};
</script>
